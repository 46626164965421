import React from "react"
import styled, { css } from "styled-components"
import { displayFlex } from "../styles/mixins"
import { setRgbaOpacity } from "../styles/theme"
import Title from "./title"
import { CONTACT } from "../reference/anchors"
import { formatString } from "../helpers/selectors"
import { media } from "../styles/mediaQueries"

const ContactForm = styled.form`
  ${displayFlex({
    flexDirection: "column",
  })}
  padding: 2em 5em;
  ${media.desktop`
    padding: 100px 5em;
  `}
  max-width: 100vw;
`

const inputCss = css`
  width: 90%;
  max-width: 400px;
  border: 1px solid ${props => setRgbaOpacity(props.theme.color.primary, 0.3)};
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 1em 0.5em;
`

const Input = styled.input`
  ${inputCss}
  height: 30px;
  :-ms-input-placeholder {
    color: black;
    border: 1px solid #000;
  }
`

const TextArea = styled.textarea`
  ${inputCss}
  height: 150px;
`

const formatEmail = ({ name, email, phone, message }) => `De: ${name}
Téléphone: ${phone}
Email: ${email}
Message: ${message}
`

const Contact = props => {
  const [values, setValue] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })
  const [sent, setSent] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const handleChange = event => {
    const target = event ? event.target : window.event.srcElement
    setValue({ ...values, [target.name]: target.value })
  }

  const handleSubmit = async () => {
    if (!values.email) return
    setSending(true)
    const response = await fetch("https://api.tipimail.com/v1/messages/send", {
      method: "POST",
      headers: {
        "X-Tipimail-ApiUser": window.atob(
          "MTIxNmRlYzVjMmViOTM5NjU4MzFhYmVhMmNmMTg4YTA="
        ),
        "X-Tipimail-ApiKey": window.atob(
          "NmNiMjkwYjhhYzVmZDM3MTAyNmJiNjM4MzAzNTA2NjU="
        ),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: window.atob("NmNiMjkwYjhhYzVmZDM3MTAyNmJiNjM4MzAzNTA2NjU="),
        to: [
          {
            address:
              process.env.NODE_ENV === "development"
                ? window.atob("YXJuYXVkQGFtYnJvc2VsbGkuaW8=")
                : window.atob("ZGlhbGxvbWlzc29mZmUuYXZvY2F0QG9yYW5nZS5mcg=="),
          },
        ],
        msg: {
          from: {
            address: window.atob("ZGlhbGxvbWlzc29mZmUuYXZvY2F0QG9yYW5nZS5mcg=="),
            personalName: "Mon site web !",
          },
          replyTo: {
            address: values.email,
            personalName: values.name,
          },
          subject: "Nouvelle prise de contact de " + values.name,
          text: formatEmail(values),
        },
      }),
    }).catch(console.error)
    setSending(false)
    if (response.statusText === "OK") {
      setSent(true)
    } else {
      console.log(response)
      alert(
        "Nous n'avons pas pu envoyer votre formulaire, veuillez réessayer ou nous contacter directement !"
      )
    }
  }
  return (
    <ContactForm
      target="dummyframe"
      autocomplete="true"
      id={formatString(CONTACT)}
      onSubmit={handleSubmit}
    >
      {/* https://stackoverflow.com/a/28060195/5225096 */}
      <iframe
        width="0"
        height="0"
        border="0"
        name="dummyframe"
        id="dummyframe"
        title="dummyframe"
      />
      <Title>Contactez-nous</Title>
      <Input
        type="text"
        name="name"
        placeholder="Votre nom"
        value={values.name}
        onChange={handleChange}
        onInput={handleChange}
        // onFocus={handleFocus}
      />
      <Input
        type="email"
        name="email"
        required
        placeholder="Votre email"
        value={values.email}
        onChange={handleChange}
        // onFocus={handleFocus}
      />
      <Input
        type="tel"
        name="phone"
        placeholder="Votre téléphone"
        value={values.phone}
        onChange={handleChange}
        // onFocus={handleFocus}
      />
      <TextArea
        type="text"
        name="message"
        placeholder="Votre message"
        value={values.message}
        onChange={handleChange}
      />
      <input type="hidden" name="_gotcha" />
      <button type="submit" disabled={sent || sending}>
        {sent ? "Merci !" : sending ? "Envoi en cours..." : "Envoyer"}
      </button>
    </ContactForm>
  )
}

export default Contact
