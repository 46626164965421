import React from "react"
import { Marker } from "react-mapbox-gl"
import styled, { css } from "styled-components"
import { obliqueBorder, displayFlex } from "../styles/mixins"
import Title from "./title"
import { formatString } from "../helpers/selectors"
import { COORDONNEES } from "../reference/anchors"
import { media } from "../styles/mediaQueries"

const CoordonneesContainerStyled = styled.div`
  width: 100%;
  max-width: 100vw;
  ${displayFlex({
    justifyContent: "space-between",
    flexDirection: "column",
  })}
  background-color: ${props => props.theme.color.secondaryBg};
  ${media.desktop`
    height: 500px;
    flex-direction: row;
  `}
  overflow: hidden;
  .mapboxgl-map {
    width: 100%;
    height: 100%;
  }
`

const MapContainer = styled.div`
  flex-shrink: 0;
  height: 300px;
  width: 100%;
  margin-bottom: 30px;
  ${props => media.desktop`
    height: 100%;
    width: 50%;
    margin-bottom: unset;
    ${obliqueBorder({
      width: props.theme.width.oblique,
      height: props.imageHeight,
      bg: props.theme.color.secondaryBg,
    })}
  `}
`

const CoordonneesStyled = styled.div`
  flex: 1;
  ${displayFlex({
    flexDirection: "column",
    justifyContent: "space-around",
  })}
`

const Label = styled.span`
  font-weight: bold;
`

const pStyle = css`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
`

const Addresse = styled.p`
  font-weight: bold;
  ${pStyle}
`

const Telephones = styled.p`
  ${pStyle}
`

const Email = styled.p`
  ${pStyle}
`

const Langues = styled.p`
  ${pStyle}
`

const Review = styled.a`
  ${pStyle}
  em {
    text-decoration: underline;
    font-style: normal;
  }
`

let ReactMapboxGl = () => () => <div />

if (typeof window !== "undefined") {
  ReactMapboxGl = require("react-mapbox-gl").default
}

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiYXJuYXVkYW1icm8iLCJhIjoiY2sxcnYzNHVnMDZxejNta2F2MHQydmV0ZiJ9.Yh-aNBFMGnCxOqg1kIYcSA",
})

const Coordonnees = ({ imageHeight }) => {
  const [email, setEmail] = React.useState("")
  const [tel, setTel] = React.useState("")
  const [fax, setFax] = React.useState("")
  const [portable, setPortable] = React.useState("")

  React.useEffect(() => {
    setEmail(window.atob("ZGlhbGxvbWlzc29mZmUuYXZvY2F0QG9yYW5nZS5mcg=="))
    setTel(window.atob("OSA3MiA2NiA0MiAzMQ=="))
    setFax(window.atob("MSA4NCAyMSAyOCA0OA=="))
    setPortable(window.atob("NiA2MiA1MSA4NCA4OA=="))
  }, [setEmail])

  return (
    <CoordonneesContainerStyled id={formatString(COORDONNEES)}>
      <MapContainer imageHeight={imageHeight}>
        <Map
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v10"
          containerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={[2.448067855843797, 48.91093216603702]}
          zoom={[14]}
        >
          <Marker
            coordinates={[2.448067855843797, 48.91093216603702]}
            anchor="bottom"
          >
            <img alt="pin" src="https://i.imgur.com/MK4NUzI.png" />
          </Marker>
        </Map>
      </MapContainer>
      <CoordonneesStyled>
        <Title>Le Cabinet</Title>
        <Addresse>
          21 rue de Carency
          <br />
          93000 BOBIGNY
        </Addresse>
        <Telephones>
          <Label>Tél: </Label>
          <a href={`tel:+33${tel.split(" ").join("")}`}>0{tel}</a>
          <br />
          <Label>Fax: </Label>
          <a href={`tel:+33${fax.split(" ").join("")}`}>0{fax}</a>
          <br />
          <Label>En cas d’urgence: </Label>
          <a href={`tel:+33${portable.split(" ").join("")}`}>0{portable}</a>
          <br />
        </Telephones>
        <Email>
          <Label>Email: </Label>
          <a href={`mailto:${email}`}>{email}</a>
          <br />
        </Email>
        <Langues>
          <Label>Langues: </Label>français, anglais
          <br />
        </Langues>
        <Review href="https://www.google.com/search?q=diallo+missoffe&rlz=1C5CHFA_enNL893NL893&oq=diallo+missoffe&#lrd=0x47e66dcffac15239:0x7098ec069c713f91,1,,,">
          <em>Cliquez ici</em> pour nous laisser un commentaire sur Google !
        </Review>
      </CoordonneesStyled>
    </CoordonneesContainerStyled>
  )
}

export default Coordonnees
